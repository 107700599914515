/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import {
  getFbAccountPermissions,
  getFbAdsAccounts,
  getFbProfile,
  getLongLiveToken,
} from "functions/facebookAds";
import {
  _onSelectAdsItem,
  formatMessageInPlaceholder,
  sweetAlert,
} from "functions/global";
import SelectDropdown from "components/SelectDropdown";
import {
  createFbAsset,
  createFbBusinessManager,
  getFbBusinessManagers,
  getFbPages,
} from "./functions";
import "./styles.css";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Input from "components/Input";
import AppButton from "components/AppButton";
import TIMEZONES from "./constants/fb-timezone-list.json";
import CURRENCIES from "./constants/currencies.json";
//stepper
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import InstructionStep from "./components/InstructionStep";
import Connected from "./components/Connected";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import AppText from "components/AppText";

const steps = ["Connect Account", "Confirm Access", "Connected"];

const BUSINESS_MANAGER_CATEGORY = [
  { name: "Advertising", value: "ADVERTISING" },
  { name: "Automotive", value: "AUTOMOTIVE" },
  { name: "Consumer packaged goods", value: "CONSUMER_PACKAGED_GOODS" },
  { name: "Ecommerce", value: "ECOMMERCE" },
  { name: "Education", value: "EDUCATION" },
  { name: "Energy and utilities", value: "ENERGY_AND_UTILITIES" },
  { name: "Entertainment and media", value: "ENTERTAINMENT_AND_MEDIA" },
  { name: "Financial services", value: "FINANCIAL_SERVICES" },
  { name: "Gaming", value: "GAMING" },
  { name: "Government and politics", value: "GOVERNMENT_AND_POLITICS" },
  { name: "Marketing", value: "MARKETING" },
  {
    name: "Organizations and associations",
    value: "ORGANIZATIONS_AND_ASSOCIATIONS",
  },
  { name: "Professional services", value: "PROFESSIONAL_SERVICES" },
  { name: "Retail", value: "RETAIL" },
  { name: "Technology", value: "TECHNOLOGY" },
  { name: "Telecom", value: "TELECOM" },
  { name: "Travel", value: "TRAVEL" },
  { name: "Other", value: "OTHER" },
];

const FbManagerAccess = (props: any) => {
  const connectId = props.connectId;
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [errorFromAdmin, setErrorFromAdmin] = useState("");
  const [adminDetails, setAdminDetails] = useState<any>(null);
  const [facebookConnected, setFacebookConnected] = useState<any>(false);
  const [facebookAds, setFacebookAds] = useState<any>(null);
  const [fbAdsAccounts, setFbAdsAccounts] = useState<any>([]);
  const [selectedFbAccount, setSelectedFbAccount] = useState<any>(null);
  const [fbAccessToken, setFbAccessToken] = useState("");
  const [fbProfile, setFbProfile] = useState<any>(null);
  const [fbPages, setFbPages] = React.useState<any>([]);
  const [fbPagesLoading, setFbPagesLoading] = React.useState(false);
  const [selectedFbPage, setSelectedFbPage] = React.useState<any>(null);
  const [fbPermissions, setFbPermissions] = useState<any[]>([]);
  const [showPagesSelection, setShowPagesSelection] = useState(true);
  const [confirmAccess, setConfirmAccess] = useState(false);

  // create or choose business states
  const [displayNewBusinessDiv, setDisplayNewBusinessDiv] = useState(false);
  const [showCreateBusiness, setShowCreateBusiness] = useState(false);
  const [businessType, setBusinessType] = useState("existing");
  const [createBusinessLoader, setCreateBusinessLoader] = useState(false);
  const [existingBusinessManagers, setExistingBusinessManagers] = useState([]);
  const [selectedBusinessManager, setSelectedBusinessManager] =
    useState<any>(null); // selected existing business manager
  const [businessManagerName, setBusinessManagerName] = useState("");
  const [selectedBusinessManagerCategory, setSelectedBusinessManagerCategory] =
    useState<any>(null);

  //create assets states
  const [showCreateAssets, setShowCreateAssets] = useState(false);
  // const [toggleAssetAccount, setToggleAssetAccount] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(TIMEZONES[7]);
  const [selectedCurrency, setSelectedCurrency] = useState(
    CURRENCIES.find((x) => x.cc === "USD")
  );
  const [assetAdAccountName, setAssetAdAccountName] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const handleBack = () => {
    if (activeStep === 1 && confirmAccess) {
      setConfirmAccess(false);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  ///////////

  const stateClearOnFbReconnect = () => {
    setFbAdsAccounts([]);
    setFbPages([]);
    setFbPermissions([]);
    setSelectedFbPage(null);
    setFbAccessToken("");
    setFacebookAds(null);
  };

  const fbAdsLogin = () => {
    try {
      window.FB.login(
        async (response: any) => {
          console.log(response, "response");
          if (response.authResponse) {
            setFacebookConnected(true);
            stateClearOnFbReconnect();
            setActiveStep(1);
            const longLiveAccessToken: any = await getLongLiveToken(
              response?.authResponse?.accessToken
            );
            setFbAccessToken(longLiveAccessToken);

            console.log(
              longLiveAccessToken,
              "longLiveAccessTokenlongLiveAccessTokenlongLiveAccessToken"
            );

            let fbProfile: any = await getFbProfile(
              longLiveAccessToken || "",
              true
            );
            setFbProfile(fbProfile);
            console.log(fbProfile, "userNamee=======");

            let fbAccounts: any = await getFbAdsAccounts(
              "debugmessage2",
              longLiveAccessToken
            );

            fbAccounts =
              fbAccounts?.data?.length > 0
                ? fbAccounts?.data.filter(
                    (x: any) =>
                      x.business &&
                      x.business.id !== adminDetails?.businessManagerId
                  )
                : [];

            const isMangerPermission =
              (fbAccounts as any)[0]?.user_tasks &&
              (fbAccounts as any)[0]?.user_tasks.includes("MANAGE");
            const isAdvertiserPermission =
              (fbAccounts as any)[0]?.user_tasks &&
              (fbAccounts as any)[0]?.user_tasks.includes("ADVERTISE");

            if (
              (fbAccounts as any).length === 1 &&
              ((fbAccounts as any)[0].id === "act_115263056585426" ||
                (fbAccounts as any)[0].id === "act_427053459589047" ||
                (((fbAccounts as any)[0].account_status !== 2 ||
                  (fbAccounts as any)[0].account_status !== 3 ||
                  (fbAccounts as any)[0].account_status !== 101) &&
                  (!(fbAccounts as any)[0]?.user_tasks ||
                    (isMangerPermission
                      ? (fbAccounts as any)[0]?.funding_source
                      : isAdvertiserPermission))))
            ) {
              console.log(fbAccounts, "in condition");
            }
            setFbAdsAccounts([...fbAccounts]);
            fetchFbPermissions();
            fetchFbPages(longLiveAccessToken, fbAccounts);
            fetchFbBusinessManagers(longLiveAccessToken);
          }
        },
        {
          scope:
            "email,public_profile,pages_manage_ads,pages_manage_metadata,pages_read_engagement,pages_read_user_content,ads_management,read_insights,instagram_basic,leads_retrieval,business_management,catalog_management",
        }
      );
    } catch (error) {
      console.log(error, "error login in");
    }
  };

  const onChangePage = (value: any) => {
    setSelectedFbPage(value);
  };

  const fetchFbPermissions = async () => {
    const fbPermissions: any = await getFbAccountPermissions();
    if (fbPermissions?.length) {
      setFbPermissions([...fbPermissions]);
    }
  };

  const getData = async () => {
    try {
      setErrorFromAdmin("");
      setLoading(true);
      const { data } = await axios.post(
        "https://api.plai.io/firebase/get_business_manager",
        {
          adminId: connectId,
          platform: "FACEBOOK",
        }
      );
      console.log(data, "data respp");
      setAdminDetails(data.results.business);
    } catch (e: any) {
      setErrorFromAdmin(e.response.data.results.error);
      console.log(e, "Eee");
    } finally {
      setLoading(false);
    }
  };

  const fetchFbBusinessManagers = async (tokenFromParam: string) => {
    try {
      const fbManagers: any = await getFbBusinessManagers(tokenFromParam);
      setExistingBusinessManagers(fbManagers);
    } catch (e) {
      setExistingBusinessManagers([]);
    }
  };
  const _createFbBusinessManager = async (tokenFromParam: string) => {
    try {
      setCreateBusinessLoader(true);
      const created: any = await createFbBusinessManager({
        accessToken: tokenFromParam,
        category: selectedBusinessManagerCategory.value,
        name: businessManagerName,
        userId: fbProfile?.id,
      });
      if (created) {
        fetchFbBusinessManagers(fbAccessToken);
        sweetAlert("Business Manager created successfully");
        setShowCreateBusiness(false);
      } else {
        sweetAlert(
          "Something went wrong while creating business manager. please try again"
        );
      }
    } catch (e: any) {
      sweetAlert(
        e?.response?.data?.results?.error ||
          "Something went wrong while creating business manager. please try again"
      );
    } finally {
      setCreateBusinessLoader(false);
    }
  };

  const createFbNewAsset = async (tokenFromParam: string) => {
    try {
      setCreateBusinessLoader(true);
      const created: any = await createFbAsset({
        accessToken: tokenFromParam,
        businessId: selectedBusinessManager.id,
        name: assetAdAccountName,
        timezoneId: selectedTimezone.id?.toString(),
        currency: selectedCurrency?.cc,
      });

      console.log(created, "Created----");
      if (!created.error) {
        sweetAlert("Asset created and selected successfully");
        setConfirmAccess(false);
        setActiveStep(1);
        setDisplayNewBusinessDiv(false);
        setShowPagesSelection(true);
        setShowCreateAssets(false);
        const currentSelectAsset = {
          id: created?.id,
          name: assetAdAccountName,
          business: {
            id: created?.business_id,
            name: selectedBusinessManager?.name,
          },
        };
        const newAccounts = [...fbAdsAccounts, currentSelectAsset];
        console.log(newAccounts, "new");
        setFbAdsAccounts([...newAccounts]);
        setSelectedFbAccount(currentSelectAsset);
      } else {
        sweetAlert(
          created.error ||
            "Something went wrong while creating asset. please try again"
        );
      }
    } catch (e: any) {
      sweetAlert(
        e?.response?.data?.results?.error ||
          "Something went wrong while creating asset. please try again"
      );
    } finally {
      setCreateBusinessLoader(false);
    }
  };

  const fetchFbPages = async (tokenFromParam: string, fbAdsAccounts: any) => {
    try {
      setFbPagesLoading(true);
      const ad_account_business_ids = fbAdsAccounts.map(
        (x: any) => x?.business?.id
      );
      console.log(fbAdsAccounts, "fbAdsAccounts filtered");
      console.log(ad_account_business_ids, "ad_account_business_ids");
      const fbPages: any = await getFbPages(tokenFromParam, connectId, true);
      const filteredPages = fbPages.length
        ? fbPages?.filter((obj: any) => {
            return (
              !obj.hasOwnProperty("business") ||
              (obj.business &&
                ad_account_business_ids.includes(obj.business.id))
            );
          })
        : [];
      console.log(filteredPages, "filteredPages");
      setFbPages([...filteredPages]);
    } catch (e) {
    } finally {
      setFbPagesLoading(false);
    }
  };

  const onSelectAdsItem = async (
    item: any,
    idKey: any,
    idName: any,
    currencyKey: any,
    type: any
  ) => {
    const isSelected = await _onSelectAdsItem(
      item,
      idKey,
      idName,
      currencyKey,
      type,
      null, //workspace id
      true
    );

    if (isSelected) {
      setSelectedFbAccount(item);
      setFacebookAds(isSelected);
    }
  };

  // Render

  const _renderFbPage = () => {
    return fbPagesLoading ? (
      <p className="fb-manager-access-insta-loading-text">
        <AppText>loading facebook pages</AppText>
      </p>
    ) : (
      <>
        <SelectDropdown
          label="Please select your Facebook page"
          data={fbPages}
          value={selectedFbPage}
          onChange={(value: any) => onChangePage(value)}
          formatOptionLabel={(data: any) => (
            <div>
              <img
                className="ads-platform-page-icon"
                src={data?.picture?.data?.url}
                alt="page-logo"
              />
              <span>{data?.name}</span>
            </div>
          )}
          getOptionLabel={
            (option: any) => option?.name //option?.username
          }
          getOptionValue={(option: any) => option?.id}
        />
      </>
    );
  };

  const renderChooseBusiness = () => {
    return showCreateBusiness ? (
      renderCreateBusiness()
    ) : (
      <div className="fb-manager-access-create-business-container">
        <p className="fb-manager-access-main-heading">
          <AppText>Use existing business manager or create a new one</AppText>
        </p>

        <FormControl>
          <RadioGroup
            value={businessType}
            onChange={(e: any) => {
              setBusinessType(e.target.value);
            }}
          >
            <FormControlLabel
              value={"existing"}
              control={<Radio />}
              label={formatMessageInPlaceholder(
                "Choose existing business manager"
              )}
            />

            <SelectDropdown
              containerClassName="selected-business-manager-dropdown"
              value={selectedBusinessManager}
              label="Please select exisiting business manager"
              data={existingBusinessManagers}
              onChange={(value: any) => {
                setSelectedBusinessManager(value);
              }}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
            />

            <FormControlLabel
              value={"new"}
              control={<Radio />}
              label={formatMessageInPlaceholder("Create new business manager")}
            />
          </RadioGroup>
        </FormControl>

        <p>
          <AppText
            values={{
              link: (
                <a
                  href={`https://app.advertgenerator.com/?connect_id=${connectId}`}
                >
                  access link
                </a>
              ),
            }}
          >
            {`
            Note: Please make sure business manager hasn't already been created.
            if it has, share the {link}
            with the admin of a business manager`}
          </AppText>
        </p>

        <button
          onClick={() => {
            if (businessType === "new") {
              setShowCreateBusiness(true);
            } else {
              if (selectedBusinessManager) {
                setShowCreateAssets(true);
                setShowCreateBusiness(false);
                setDisplayNewBusinessDiv(false);
              } else {
                sweetAlert("Please select existing business manager");
              }
            }
          }}
          className="fb-manager-access-connect-btn"
        >
          <AppText>Continue</AppText>
        </button>

        <p
          onClick={() => {
            setShowPagesSelection(true);
            setDisplayNewBusinessDiv(false);
          }}
          className="go-back-btn"
        >
          <AppText>GO BACK</AppText>
        </p>
      </div>
    );
  };

  const renderCreateBusiness = () => {
    return (
      <div>
        <p className="fb-manager-access-main-heading">
          <AppText>Create a new business manager</AppText>
        </p>

        <label className="fb-manager-access-input-label">
          <AppText>New Business Manager Name</AppText>
        </label>
        <Input
          inputChildClassName="create-business-name-input-child"
          className={"create-business-name-input"}
          value={businessManagerName}
          onChange={(e: any) => setBusinessManagerName(e.target.value)}
        />

        <SelectDropdown
          value={selectedBusinessManagerCategory}
          label="Your business industry"
          data={BUSINESS_MANAGER_CATEGORY}
          onChange={(value: any) => {
            setSelectedBusinessManagerCategory(value);
            // fetchFbPages(fbAccessToken);
          }}
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option: any) => option.value}
        />

        <AppButton
          onClick={() => _createFbBusinessManager(fbAccessToken)}
          className="fb-manager-access-connect-btn"
          title="Create new business manager"
          loading={createBusinessLoader}
        />

        <p onClick={() => setShowCreateBusiness(false)} className="go-back-btn">
          <AppText>GO BACK</AppText>
        </p>
      </div>
    );
  };

  const renderCreateAssets = () => {
    return (
      <div>
        <p className="fb-manager-access-main-heading">
          <AppText>Create new Facebook Assets</AppText>
        </p>

        <p>
          <AppText
            values={{ managerName: <b>{selectedBusinessManager?.name}</b> }}
          >
            {`These assets will be created within the {managerName} business
            manager`}
          </AppText>
        </p>

        <p>
          <AppText>
            Select and name the new ad account you would like to create below:
          </AppText>
        </p>

        <div className="fb-manager-assets-switch-container">
          {/* <AppSwitch
            checked={toggleAssetAccount}
            onChange={(e) => setToggleAssetAccount(e.target.checked)}
          /> */}

          <div className="fb-manager-assets-switch-subcontainer">
            <p className="fb-manager-assets-switch-title">
              <AppText>Ad Account</AppText>
            </p>

            <>
              <Input
                placeholder="asset account name"
                className={"create-business-name-input"}
                value={assetAdAccountName}
                onChange={(e: any) => setAssetAdAccountName(e.target.value)}
              />

              <SelectDropdown
                value={selectedCurrency}
                label="Currency"
                data={CURRENCIES}
                onChange={(value: any) => {
                  setSelectedCurrency(value);
                }}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.cc}
              />

              <SelectDropdown
                defaultValue={selectedTimezone}
                label="Timezone"
                data={TIMEZONES}
                onChange={(value: any) => {
                  setSelectedTimezone(value);
                }}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.code}
              />
            </>
          </div>
        </div>

        <AppButton
          onClick={() => createFbNewAsset(fbAccessToken)}
          className="fb-manager-access-connect-btn"
          title="Continue and create new ad account"
          loading={createBusinessLoader}
        />

        <p
          onClick={() => {
            setDisplayNewBusinessDiv(true);
            setShowCreateAssets(false);
          }}
          className="go-back-btn"
        >
          <AppText>GO BACK</AppText>
        </p>
      </div>
    );
  };

  const handleGrantAccess = () => {
    if (!selectedFbAccount) {
      sweetAlert("Please select ad account");
    } else {
      setConfirmAccess(true);
    }
  };

  const renderAccountAndPageSelection = () => {
    return (
      <div>
        <p
          onClick={() => {
            setShowPagesSelection(false);
            setDisplayNewBusinessDiv(true);
          }}
          className="fb-manager-access-create-asset-link"
        >
          <AppText>
            Create a new Business Manager, Ad Account or other assets
          </AppText>
        </p>
        {fbAdsAccounts?.length > 0 ? (
          <div>
            <SelectDropdown
              value={selectedFbAccount}
              label="Please select Ad account"
              data={fbAdsAccounts}
              onChange={(value: any) => {
                onSelectAdsItem(value, idKey, idName, currencyKey, "FACEBOOK");
              }}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
            />

            {_renderFbPage()}

            <AppButton
              style={{ marginTop: 20 }}
              title="Confirm Access"
              onClick={() => handleGrantAccess()}
              className="fb-manager-access-btn"
            />
          </div>
        ) : (
          <p className="fb-manager-access-no-ad-acc-error">
            <AppText>
              You do not have access to ad accounts managed by Business Manager.
              Either the Business Manager associated with your ad account is not
              yours, or the Business Manager's admin is the same as your admin.
            </AppText>
          </p>
        )}
      </div>
    );
  };

  const renderConnectFacebook = () => {
    return (
      <>
        <div className="fb-manager-access-connect-container">
          <p className="fb-manager-access-connect-heading">
            <AppText>Connect your Facebook Account</AppText>
          </p>
          <button
            className="fb-manager-access-connect-btn"
            onClick={fbAdsLogin}
          >
            <AppText>{facebookConnected ? "Reconnect" : "Connect"}</AppText>{" "}
            Facebook
          </button>
        </div>
      </>
    );
  };

  const renderStepper = () => {
    return (
      <>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepButton color="inherit">{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          <>
            {activeStep !== 2 && (
              <div className="fb-manager-access-details-container">
                <p className="fb-manager-access-details-heading">
                  <AppText>Request Details</AppText>
                </p>
                <p className="fb-manager-access-details-subheading">
                  <b>
                    <AppText>Meta Business Manager:</AppText>{" "}
                  </b>
                  {adminDetails?.businessManagerName}{" "}
                  {`(${adminDetails?.businessManagerId})`}
                </p>
                <p className="fb-manager-access-details-subheading">
                  <b>
                    <AppText>Access Level:</AppText>{" "}
                  </b>{" "}
                  <AppText>Manage</AppText>
                </p>
              </div>
            )}
            {activeStep === 0 && renderConnectFacebook()}
            {activeStep === 1 ? (
              confirmAccess ? (
                <InstructionStep
                  selectedFbPage={selectedFbPage}
                  connectId={connectId}
                  fbAccessToken={fbAccessToken}
                  selectedFbAccount={selectedFbAccount}
                  adminDetails={adminDetails}
                  setActiveStep={setActiveStep}
                  setConfirmAccess={setConfirmAccess}
                />
              ) : (
                renderAccountAndPageSelection()
              )
            ) : null}
            {activeStep === 2 && (
              <Connected
                selectedFbAccount={selectedFbAccount}
                adminDetails={adminDetails}
              />
            )}

            {activeStep !== 2 && (
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  <AppText>Back</AppText>
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {facebookConnected && activeStep === 0 && (
                  <Button onClick={() => setActiveStep(1)}>
                    <AppText>Next</AppText>
                  </Button>
                )}
              </Box>
            )}
          </>
        </div>
      </>
    );
  };

  const idKey = "facebookAds.accountId";
  const idName = "facebookAds.accountName";
  const currencyKey = "facebookAds.currencyCode";

  return (
    <div>
      {loading ? (
        <Oval
          height={40}
          width={40}
          color={"var(--color-primary)"}
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px",
          }}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="var(--text-grey)"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : (
        <div className="fb-manager-access-main-container">
          {adminDetails ? (
            <div>
              <div className="fb-manager-access-card">
                {!displayNewBusinessDiv && !showCreateAssets && (
                  <p className="fb-manager-access-main-heading">
                    <AppText
                      values={{
                        managerName: adminDetails?.businessManagerName,
                      }}
                    >
                      {`{managerName} is requesting Partner Access to your Facebook Assets`}
                    </AppText>
                  </p>
                )}

                {showPagesSelection && renderStepper()}
                {showCreateAssets && renderCreateAssets()}
                {displayNewBusinessDiv && renderChooseBusiness()}
              </div>
              <video
                src="https://firebasestorage.googleapis.com/v0/b/plai-v3.appspot.com/o/manager-access-videos%2FLink%20Bridge%20(1).mp4?alt=media&token=1d0304c4-5b32-44c1-9f50-0d29a0f802ed"
                controls={true}
                className="fb-manager-access-video"
              />
            </div>
          ) : (
            <p style={{ textAlign: "center" }}>
              <FontAwesomeIcon
                style={{ marginBottom: 10 }}
                fontSize={50}
                icon={faTriangleExclamation}
              />{" "}
              <br /> {errorFromAdmin}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default FbManagerAccess;
